<template>
  <b-card>
    <b-row>
      <h6 class="section-label mx-1 mb-2">Products</h6>
      <b-col cols="12" class="mb-2">
        <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" to="/takeaway/products/new">
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Add New</span>
        </b-button>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input v-model="searchTerm" placeholder="Search" type="text" class="d-inline-block" />
            </div>
          </b-form-group>
        </div>

        <!-- table -->
        <vue-good-table
          :columns="columns"
          :rows="rows"
          v-if="!busy"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm,
          }"
          :select-options="{
            enabled: false,
            selectOnCheckboxOnly: false, // only select when checkbox is clicked instead of the row
            selectionInfoClass: 'custom-class',
            selectionText: 'rows selected',
            clearSelectionText: 'clear',
            disableSelectInfo: false, // disable the select info panel on top
            selectAllByGroup: false, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
          }"
          :pagination-options="{
            enabled: true,
            perPage: pageLength,
          }"
          :group-options="{
            enabled: true,
          }"
        >
          <template slot="table-row" slot-scope="props">
            <!-- Column: Action -->
            <span v-if="props.column.field === 'price'"> €{{ parseFloat(props.row.price).toFixed(2) }} </span>
            <span v-if="props.column.field === 'prod_order'">
              {{ props.row.prod_order }}
            </span>
            <span v-if="props.column.field === 'title'">
              {{ props.row.title }}
            </span>
            <span v-if="props.column.field === 'image'">
              <img :src="props.row.image" alt="product image" class="img-fluid" style="width: 100px; object-fit: contain" v-if="props.row.image" />
              <img
                :src="missing_image.missing_image_placeholder"
                alt="product image"
                class="img-fluid"
                style="width: 100px; object-fit: contain"
                v-else-if="missing_image.missing_image_placeholder"
              />
            </span>
            <span v-if="props.column.field === 'sku'">
              {{ props.row.sku }}
            </span>
            <span v-if="props.column.field === 'action'">
              <b-button type="submit" variant="warning" block :to="'/takeaway/products/' + props.row.id">
                <feather-icon icon="BookOpenIcon" class="mr-50" />
                <span>Edit</span>
              </b-button>
            </span>
          </template>
          <!-- pagination -->
          <template slot="pagination-bottom" slot-scope="props">
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap"> Showing 1 to </span>
                <b-form-select
                  v-model="pageLength"
                  :options="['500', '1000', '2500']"
                  class="mx-1"
                  @input="(value) => props.perPageChanged({ currentPerPage: value })"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value) => props.pageChanged({ currentPage: value })"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BAvatar, BBadge, BPagination, BFormGroup, BFormInput, BFormSelect, BDropdownItem, BDropdown, BCard, BRow, BCol, BButton } from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import _ from "lodash";
import useJwt from "@/auth/jwt/useJwt";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import Ripple from "vue-ripple-directive";

export default {
  components: {
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdownItem,
    BCard,
    BRow,
    BCol,
    BDropdown,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      missing_image: JSON.parse(localStorage.getItem("settings")),
      busy: false,
      pageLength: 500,
      categories: [],
      columns: [
        {
          label: "POS",
          field: "prod_order",
          width: "100px",
        },
        {
          label: "Thumb",
          field: "image",
          width: "100px",
        },
        {
          label: "Name",
          field: "title",
        },
        {
          label: "SKU",
          field: "sku",
        },
        {
          label: "Price",
          field: "price",
        },
        {
          label: "Action",
          field: "action",
          width: "150px",
        },
      ],
      rows: [],
      searchTerm: "",
    };
  },
  methods: {},
  async created() {
    await useJwt
      .list_categories()
      .then((response) => {
        localStorage.setItem("userCategories", JSON.stringify(response.data.data));
        this.categories = response.data.data;
      })
      .catch(() => {
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't downlad your products data from the server.`,
          },
        });
      });
    await useJwt
      .list_products()
      .then((response) => {
        localStorage.setItem("userProducts", JSON.stringify(response.data.data));
        let data = response.data.data;
        let categories = [];
        let object = {};
        let newObject = [];
        let newObjectUnCat = [];
        let newData;

        for (let x = 0; x < data.length; x++) {
          delete data[x].meta_title;
          delete data[x].slug;
          delete data[x].summary;
          delete data[x].discount;
          delete data[x].discount_starts_at;
          delete data[x].quantity;
          delete data[x].is_deleted;
          delete data[x].discount_ends_at;

          if (data[x].categories) {
            data[x].categories.replace(" ", "");
            if (data[x].categories.length) {
              if (data[x].categories.split(",").length > 1) {
                data[x].mcat = data[x].categories;
                data[x].categories = "";

                for (let y = 0; y < data[x].mcat.split(",").length; y++) {
                  newData = {};
                  newData.id = data[x].id;
                  newData.title = data[x].title;
                  newData.price = parseFloat(data[x].price).toFixed(2);
                  newData.prod_order = data[x].prod_order;
                  newData.sku = data[x].sku;
                  newData.image = data[x].image;
                  newData.categories = data[x].mcat.split(",")[y];

                  newObject.push(newData);
                }

                // newObject= _.uniqBy(newObject, 'sku');
                delete data[x];
                _.forEach(newObject, (val) => {
                  data.push(val);
                });
                newObject = [];
              }
            }
          } else {
            newData = {};
            newData.id = data[x].id;
            newData.title = data[x].title;
            newData.price = parseFloat(data[x].price).toFixed(2);
            newData.prod_order = data[x].prod_order;
            newData.sku = data[x].sku;
            newData.categories = ["hidden"];

            newObjectUnCat.push(newData);
          }
        }
        if (this.categories) {
          data = _.sortBy(data, ["prod_order"]);
          for (let x = 0; x < data.length; x++) {
            if (data[x] && data[x].categories && data[x].categories.length) {
              for (let y = 0; y < data[x].categories.split(",").length; y++) {
                let update = false;

                for (let z = 0; z < categories.length; z++) {
                  if (categories[z].id == data[x].categories.split(",")[y]) {
                    object = {};
                    update = true;
                    categories[z].children.push(data[x]);
                    break;
                  }
                }
                if (!update) {
                  object = {};
                  let cat = _.find(this.categories, (o) => {
                    if (o.id == data[x].categories.split(",")[y]) return o;
                  });

                  if (cat != undefined) {
                    object.label = cat.title;
                    object.position = cat.position;
                  }
                  update = false;
                  object.mode = "span";
                  object.id = data[x].categories.split(",")[y];
                  object.html = false;
                  object.children = [];
                  object.children.push(data[x]);

                  categories.push(object);
                }
              }
            }
          }
        }
        if (newObjectUnCat.length) {
          object.mode = "span";
          object.id = "x";
          object.html = false;
          object.children = [];
          object.label = "Uncategorised Products";
          object.position = 0;
          for (let x = 0; x < newObjectUnCat.length; x++) {
            object.children.push(newObjectUnCat[x]);
          }
          categories.push(object);
        }
        categories = _.sortBy(categories, ["position"]);
        let cleanCategories = [];
        _.forEach(categories, (cat) => {
          cleanCategories.push({
            children: _.uniqBy(cat.children, "sku"),
            html: cat.html,
            label: cat.label,
            position: cat.position,
            id: cat.id,
            mode: cat.mode,
          });
        });
        return (this.rows = cleanCategories);
      })
      .catch((e) => {
        console.error(e);
        this.$toast({
          component: ToastificationContent,
          position: "top-right",
          props: {
            title: `Error`,
            icon: "CropIcon",
            variant: "danger",
            text: `We couldn't products your order data from the server.`,
          },
        });
      });
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";

.vgt-table.bordered th.vgt-row-header {
  background: #e7eaf7;
}
</style>
